import React from 'react';
import { Spin } from 'antd';
import s from './s.module.less';
import { closeKiwiPlanAdvertisingModal, closeKlarityPlanAdvertisingModal, openSubscriptionModal } from 'utils/globalLayerControl';
import GlobalLayerStore from 'store/GlobalLayer';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import { TSubscriptionItem, TSubscriptionType } from 'types/subscription';
import SubscriptionButton from 'components/SubscriptionButton';

const KiwiPlanAdvertise = () => {
    const [plansData, loadingSubscriptionPlan] = useSubscriptionPlan();
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const [item, setItem] = React.useState<TSubscriptionItem>();

    const handleCheckAll = () => {
        closeKiwiPlanAdvertisingModal();
        openSubscriptionModal(true);
    };

    React.useEffect(() => {
        const bestValuePlan = plansData?.kiwiPlan?.find((plan) => plan.type === TSubscriptionType.GROWTH);
        setItem(bestValuePlan);
    }, [plansData]);

    return (
        <div className={s.wrap}>
            <div className={s.limit}>Limited time offer</div>
            <Spin spinning={!!loadingSubscriptionPlan}>
                <div className={s.title}>Upgrade to <span className={s.high}>Growth plan</span> to attract more patients & grow your practice</div>
                <div className={s.subTitle}>Upgrade to a 6-month plan and enjoy <span className={s.strong}>18% off.</span> If you have a coupon, don’t forget to use it!</div>
                <div className={s.body}>
                    <div className={s.banner} />
                    {
                        item?.semiAnnualPlanId &&
                        <SubscriptionButton
                            planId={item?.semiAnnualPlanId}
                            size="large"
                            loading={!!loadingSubscriptionPlan}
                            type="primary"
                            className={s.mainBtn}
                            block
                            text="🚀 Start 6-month subscription to save 18%"
                            plan={item}
                        />
                    }
                    <a className={s.link} onClick={handleCheckAll}>Check out all plans</a>
                </div>
            </Spin>
        </div>
    );
};

export default KiwiPlanAdvertise;

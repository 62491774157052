// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__playerWrap--O0nxu {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}
.s-module__playerWrap--O0nxu .s-module__header--jCVY_ {
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.s-module__playerWrap--O0nxu .s-module__header--jCVY_ .s-module__closeIcon--pl1d8 {
  width: 36px;
  height: 36px;
  cursor: pointer;
}
#s-module__youtubePlayer--hTPOQ {
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/components/YoutubePlayer/s.module.less"],"names":[],"mappings":"AAAA;EAWI,YAAA;EACA,qCAAA;AATJ;AAHA;EAgBQ,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AAVR;AAVA;EAuBY,WAAA;EACA,YAAA;EACA,eAAA;AAVZ;AAeA;EAGI,YAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;AAfJ","sourcesContent":[".playerWrap {\n    // position: fixed;\n    // height: 100%;\n    // width: 100%;\n    // min-width: 100vw;\n    // min-height: 100vh;\n    // z-index: 1000;\n    // left: 0;\n    // top: 0;\n    // right: 0;\n    // bottom: 0;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.65);\n\n    .header {\n\n        width: 100%;\n        padding: 16px;\n        display: flex;\n        align-items: center;\n        justify-content: flex-end;\n\n        .closeIcon {\n            width: 36px;\n            height: 36px;\n            cursor: pointer;\n        }\n    }\n}\n\n#youtubePlayer {\n    // max-width: 800px;\n    // max-height: 460px;\n    height: 100%;\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"playerWrap": `s-module__playerWrap--O0nxu`,
	"header": `s-module__header--jCVY_`,
	"closeIcon": `s-module__closeIcon--pl1d8`,
	"youtubePlayer": `s-module__youtubePlayer--hTPOQ`
};
export default ___CSS_LOADER_EXPORT___;

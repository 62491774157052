import React, { useCallback, useEffect, useRef, useState } from 'react';
import s from './s.module.less';

interface IProps {
    videoId?: string;
    show?: boolean;
    setShow?: (show: boolean) => void;
    playerWrapClassName?: string;
}

declare global {
    interface Window {
        YT: any;
        onYouTubeIframeAPIReady: () => void;
    }
}

const youtubePlayerHeight = '100%';

const YouTubePlayer = ({ videoId, show, setShow, playerWrapClassName = '' }: IProps) => {
    const playerRef = useRef(null); // 引用播放器 DOM 节点
    const [player, setPlayer] = useState<any>(null); // 保存播放器实例

    useEffect(() => {
        if (!show && player) {
            player.destroy();
        }
    }, [show, player]);

    // 加载 YouTube IFrame API
    useEffect(() => {
        setTimeout(() => {
            if (typeof window !== 'undefined') {
                // 检查 YouTube API 是否已加载，如果没有则加载
                if (!window.YT) {
                    const tag = document.createElement('script');
                    tag.src = 'https://www.youtube.com/iframe_api';
                    const firstScriptTag = document.getElementsByTagName('script')[0];
                    if (firstScriptTag.parentNode) {
                        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                    }

                    // 在 YouTube API 加载完成后初始化播放器
                    window.onYouTubeIframeAPIReady = () => {
                        if (!window.YT.Player) {
                            return;
                        }
                        setPlayer(
                            new window.YT.Player(playerRef.current, {
                                height: youtubePlayerHeight,
                                width: '100%',
                                videoId,
                                events: {
                                    onReady: (event: any) => {
                                        event.target.playVideo(); // 视频准备好后自动播放
                                    },
                                },
                            }),
                        );
                    };
                } else {
                    // 如果 YouTube API 已经加载过，直接创建播放器
                    if (!window.YT.Player) {
                        return;
                    }
                    setPlayer(
                        new window.YT.Player(playerRef.current, {
                            height: youtubePlayerHeight,
                            width: '100%',
                            videoId,
                            events: {
                                onReady: (event: any) => {
                                    event.target.playVideo(); // 视频准备好后自动播放
                                },
                            },
                        }),
                    );
                }
            }
        }, 500);

        // 清理函数，在组件卸载时销毁播放器
        return () => {
            if (player) {
                player.destroy();
            }
        };
    }, [show, videoId]); // 仅在组件挂载时执行

    // // 监听 videoId 变化并更新视频
    // useEffect(() => {
    //     if (player && videoId) {
    //         // player.loadVideoById(videoId); // 更改视频 ID 时更新播放器
    //     }
    // }, [videoId, player]); // videoId 或 player 改变时重新执行

    const handleClose = useCallback(() => {
        setShow?.(false);
    }, []);

    return (
        // <div className={s.playerWrap}>
        <div className={`${s.playerWrap} ${playerWrapClassName}`} style={{ opacity: show ? '1' : '0', left: show ? '0px' : '-10000px' }} onClick={handleClose}>
            {/* <div className={s.header}>
                <div className={s.closeIcon} onClick={handleClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                        <path d="M6 18L18 6M6 6L18 18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div> */}
            <div ref={playerRef} className={s.player} id="youtubePlayer" />
        </div>
    ); // 渲染 YouTube 播放器
};

export default YouTubePlayer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__modalWrap--CWlqX .s-module__calendlyWrap--KyhIt {
  height: 600px;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/WebinarBooking/s.module.less"],"names":[],"mappings":"AAEA;EAMI,aAAA;EACA,gBAAA;AANJ","sourcesContent":[".wrap {}\n\n.modalWrap {\n  :global {\n    .ant-modal-body {}\n  }\n\n  .calendlyWrap {\n    height: 600px;\n    overflow-y: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrap": `s-module__modalWrap--CWlqX`,
	"calendlyWrap": `s-module__calendlyWrap--KyhIt`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Modal } from 'antd';
import s from './s.module.less';
import { CALENDLY_LINK } from 'constants/common';
import { getRandomString } from 'utils/common';
import { EWebinarScheduleType } from 'types/common';
import { calendlyProviderSave } from 'api/common';
import { web } from 'webpack';

interface IProps {
    show: boolean,
    link: string,
    onClose: (booked: boolean) => void,
    modalHeader?: JSX.Element,
    webinarScheduleType?: EWebinarScheduleType,
}

const WebinarBooking = ({
    show,
    link,
    onClose,
    modalHeader,
    webinarScheduleType,
}: IProps) => {
    const idRef = React.useRef<string>(getRandomString());
    const timeOutRef = React.useRef<any>(null);
    const isSubmitted = React.useRef<boolean>(false);
    const isSuccessful = React.useRef<boolean>(false);

    React.useEffect(() => {
        isSubmitted.current = false;
        isSuccessful.current = false;
    }, [link])

    React.useEffect(() => {
        if (show) {
            timeOutRef.current = setTimeout(() => {
                (window as any).Calendly.initInlineWidget({
                    url: link || `${CALENDLY_LINK.ONBOARDING}`,
                    // url: `https://calendly.com/kunqian-fan/11?${objString}`,
                    parentElement: document.getElementById(idRef.current),
                });
            }, 300);
        } else {
            if (timeOutRef.current) {
                clearTimeout(timeOutRef.current);
            }
        }
        return () => {
            if (timeOutRef.current) {
                clearTimeout(timeOutRef.current);
            }
        }
    }, [show])

    const doSubmit = async () => {
        if (!isSubmitted.current && webinarScheduleType) {
            isSubmitted.current = true;
            const res = await calendlyProviderSave(webinarScheduleType);
        }
    }

    React.useEffect(() => {
        const isCalendlyScheduleEvent = (e: any) => {
            return e.data.event &&
                e.data.event.indexOf('calendly') === 0 && e?.data?.event === 'calendly.event_scheduled';
        };
        const callback = (e: any) => {
            if (isCalendlyScheduleEvent(e)) {
                if (e?.data?.event === 'calendly.event_scheduled') {
                    doSubmit();
                }
            }
        }
        window.addEventListener(
            'message',
            callback
        );
        return () => {
            window.removeEventListener(
                'message',
                callback
            );
        }
    }, [])

    return (
        <div className={s.wrap}>
            <Modal
                destroyOnClose
                open={show}
                width={1200}
                className={s.modalWrap}
                cancelButtonProps={{
                    style: { display: 'none' },
                }}
                onCancel={() => {
                    onClose(isSuccessful.current);
                }}
                okText="Close"
                onOk={() => {
                    onClose(isSuccessful.current);
                }}
                footer={null}
                title={null}
            >
                <div className={s.sModalWrap}>
                    {modalHeader &&
                        <div className={s.header}>{modalHeader}</div>
                    }
                    <div id={idRef.current} className={s.calendlyWrap} />
                </div>
            </Modal>
        </div>
    );
};

export default WebinarBooking;
